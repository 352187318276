import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { Crown, X } from 'lucide-react';

const CountdownTimer = () => {
  const [timeLeft, setTimeLeft] = React.useState(calculateTimeLeft());

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  function calculateTimeLeft() {
    const difference = +new Date('2024-09-13T15:00:00-04:00') - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  }

  const timerComponents = Object.keys(timeLeft).map((interval) => (
    <div key={interval} className="flex flex-col items-center">
      <div className="bg-slate-50 shadow  text-purple-600 rounded-lg p-2 mb-1">
        <span className="text-2xl font-bold">{timeLeft[interval]}</span>
      </div>
      <span className="text-sm uppercase">{interval}</span>
    </div>
  ));

  return (
    <div className="text-center mt-4">
      <h3 className="text-xl font-semibold mb-2">Launching Soon:</h3>
      <div className="flex justify-center space-x-4">
        {timerComponents.length ? timerComponents : <span>We're live!</span>}
      </div>
    </div>
  );
};

const LPHero = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "//embed.typeform.com/next/embed.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const leftColumnItems = [
    {
      src: "/images/teen_usa.jpg",
      username: "missteenusa"
    },
    {
      src: "/images/thenamncjrteen.jpg",
      username: "thenamncjrteen"
    },
    {
      src: "/images/missamericami.jpg",
      username: "missamericami"
    },
    {
      src: "/images/womanentrepreneurintl.jpg",
      username: "womanentrepreneurintl"
    },
  ];

  const rightColumnItems = [
    {
      src: "/images/officialmissgrandusa.jpg",
      username: "officialmissgrandusa"
    },
    {
      src: "/images/amberh.pageants.jpg",
      username: "amberh.pageants"
    },
    {
      src: "/images/usanationalmiss.jpg",
      username: "usanationalmiss"
    },
    {
      src: "/images/ultimateinternationalmiss.jpg",
      username: "ultimateinternationalmiss"
    },
  ];

  return (
    <div className="relative h-screen overflow-hidden flex justify-center items-center px-4">
      {/* Left side content */}
      <div className="w-full md:w-1/2 p-4 md:p-8 flex flex-col justify-center items-center text-center z-10">
        <div className="absolute top-4 left-4 flex items-center">
          <Crown size={30} className="text-purple-600 mr-2" />
          <h2 className="text-xl tracking-tight font-bold text-black">
            The Crown List
          </h2>
        </div>
        <div>
          <p className="text-lg sm:text-xl tracking-tight font-medium text-gray-600 mb-4">
            Your Community for All Things Pageantry
          </p>
          <h1 className="text-3xl sm:text-4xl md:text-5xl tracking-tight font-extrabold text-gray-900">
            <span className="block">Discover Your Path to</span>
            <span className="block text-purple-600">Pageant Excellence</span>
          </h1>
          <p className="mt-3 text-center text-sm max-w-sm md:max-w-3xl sm:text-base md:text-lg text-gray-500">
            Search, compare, and connect with top-tier pageants, vendors, and industry experts.
          </p>
         
          <div className="mt-6 flex justify-center">
            <form className="w-full max-w-md">
              <div className="flex">
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="w-full px-4 py-2 border border-gray-300 rounded-l focus:outline-none focus:ring-2 focus:ring-purple-600"
                />
                <button
                  type="submit"
                  className="px-6 py-2 bg-purple-600 text-white rounded-r hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-600"
                >
                  Join
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Right side image columns */}
      <div className="hidden md:flex w-1/2 relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-white via-transparent to-white z-10" />
        {/* Left Column */}
        <div className="w-1/2 overflow-hidden px-2">
          <motion.div
            className="flex flex-col gap-4"
            animate={{
              y: [-100 * leftColumnItems.length, 0],
            }}
            transition={{
              y: {
                repeat: Infinity,
                repeatType: "loop",
                duration: 25,
                ease: "linear",
              },
            }}
          >
            {[...leftColumnItems, ...leftColumnItems].map((item, index) => (
              <div key={index} className="h-[400px] w-full flex-shrink-0 relative">
                <img
                  src={item.src}
                  alt={`Left column image ${index + 1}`}
                  className="w-full h-full object-cover rounded-lg"
                />
                <div className="absolute bottom-2 right-2 bg-black bg-opacity-50 text-white px-2 py-1 rounded text-sm">
                  @{item.username}
                </div>
              </div>
            ))}
          </motion.div>
        </div>
        {/* Right Column */}
        <div className="w-1/2 overflow-hidden px-2">
          <motion.div
            className="flex flex-col gap-4"
            animate={{
              y: [0, -100 * rightColumnItems.length],
            }}
            transition={{
              y: {
                repeat: Infinity,
                repeatType: "loop",
                duration: 25,
                ease: "linear",
              },
            }}
          >
            {[...rightColumnItems, ...rightColumnItems].map((item, index) => (
              <div key={index} className="h-[400px] w-full flex-shrink-0 relative">
                <img
                  src={item.src}
                  alt={`Right column image ${index + 1}`}
                  className="w-full h-full object-cover rounded-lg"
                />
                <div className="absolute bottom-2 right-2 bg-black bg-opacity-50 text-white px-2 py-1 rounded text-sm">
                  @{item.username}
                </div>
              </div>
            ))}
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default LPHero;